import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../requests-service/requests.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-tools-resources',
  templateUrl: './tools-resources.component.html',
  styleUrls: ['./tools-resources.component.scss'],
  providers: [RequestsService],
})
export class ToolsResourcesComponent implements OnInit {
  settingsJSON = null;
  isLoading = true;
  errorLoadingData = false;
  errorMessage = `Unfortunately we aren\'t able to load the educator resources at this time.
  Please contact Customer Service at 1-800-724-2222 or email digitalservice@scholastic.com if you continue to experience this issue.`;
  url = this.auth.env === 'prod' ?
    'https://dme3v5onn24dy.cloudfront.net' :
    'https://d1j0qeidun35n1.cloudfront.net';
  mediaPlayerBaseUrl = this.auth.env !== 'prod' ?
    'https://media-player-nonprod.digital.scholastic.com/?videoSrcUrl=https://media-nonprod.digital.scholastic.com/output/' :
    'https://media-player.digital.scholastic.com/?videoSrcUrl=https://media.digital.scholastic.com/output/';

  constructor(
    private requestsService: RequestsService,
    // @ts-ignore
    private _DomSanitizationService: DomSanitizer,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.fetchSettings();
  }

  /**
  * This function will fetch data.json from s3 bucket and start building display object
  * @returns void
  */
  fetchSettings(): void {
    const fetchSettingsCB = (result) => {
      try {
        this.settingsJSON = JSON.parse(result);
        this.buildDisplayData(0);
      } catch (error) {
        this.errorLoadingData = true;
        console.error(error);
      }
    };
    this.requestsService.getContentFromUrl(this.url + '/data.json', fetchSettingsCB);
  }

  /**
  * This function will recursively go through each resource heading and saves images/pdf into the object
  * @returns void
  */
  buildDisplayData(index: any): void {
    const currentHeadings = this.settingsJSON.rows.resourceHeadings[index];
    if (!currentHeadings) {
      // data is ready for display
      this.isLoading = false;
      return;
    }
    // start with first resource in each resource heading
    const indexForResources = 0;
    this.fetchResourcesFromCloudFront(currentHeadings, indexForResources, index);
  }

  /**
  * This function will recursively fetch then save image for each resouce along with its pdf source path
  * @returns void
  */
  fetchResourcesFromCloudFront(currentHeadings: any, indexForResources: any, parentIndex: any): void {
    const currentResource = currentHeadings.resources[indexForResources];
    if (!currentResource) {
      const newParentIndex = parentIndex + 1;
      this.buildDisplayData(newParentIndex);
      return;
    }

    const imagePath = this.url + '/resources/' + currentResource['imageFilename'];
    currentResource['imageSrc'] = imagePath;

    currentResource['target'] = this.createTarget(currentResource);
    if (currentResource['sections'] && Array.isArray(currentResource['sections'])) {
      for (const section of currentResource['sections']) {
        section['target'] = this.createTarget(section);
      }
    }

    const newIndex = indexForResources + 1;
    this.fetchResourcesFromCloudFront(currentHeadings, newIndex, parentIndex);
  }

  createTarget(resource: {filename?: string, link?: string, mediaId?: string, title?: string}): string {
    let target = 'void(0)';
    if (resource.filename) {
      // If a filename is defined, such file should be within the resources folder of the s3 bucket
      target = this.url + '/resources/' + resource.filename;
    } else if (resource.mediaId) {
        target = `${this.mediaPlayerBaseUrl}${resource.mediaId}/media/playlist.m3u8&title=${resource.title}`;
    } else if (resource.link) {
      // If a link is defined, we can just direct the user to such link
      target = resource.link;
    }
    return target;
  }
}
